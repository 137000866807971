export function generateErrorInitialState(formData: any) {
  const generateErrorStructure = (data: any): any => {
    if (Array.isArray(data)) {

      return data.map((item: any) => generateErrorStructure(item));
    } else if (typeof data === "object" && data !== null) {
      const errorStructure: any = {};
      for (const key in data) {
        if (Array.isArray(data[key])) {
          errorStructure[key] = data[key].map((item: any) => generateErrorStructure(item));
        } else {
          errorStructure[key] = typeof data[key] === 'string' ? '' : generateErrorStructure(data[key]);
        }
      }

      return errorStructure;
    } else {

      return "";
    }
  };

  return generateErrorStructure(formData);
}

export const generativeFieldErrorCheck = (prevErrors: any, fieldName: any, value: any, index: number) => {
  const updatedFireArms = Array.isArray(prevErrors.fireArms)
    ? prevErrors.fireArms.map((armsError: any, i: number) =>
      i === index
        ? {
          ...armsError,
          [fieldName.replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())]: value === "" ? `${fieldName} is required` : "",
        }
        : armsError,
    )
    : [];

  return { ...prevErrors, fireArms: updatedFireArms };
};

export const generativeFieldErrorCheckForManyTypes = (prevErrors: any, fieldName: any, value: any, index: number, fieldType: string) => {
  const updatedField = Array.isArray(prevErrors[fieldType])
    ? prevErrors[fieldType].map((fieldError: any, i: number) =>
      i === index
        ? {
          ...fieldError,
          [fieldName.replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())]: value === "" ? `${fieldName} is required` : "",
        }
        : fieldError,
    )
    : [];

  return { ...prevErrors, [fieldType]: updatedField };
};

const validateForm = (formData: any) => {
  const errors: any = {};

  const formatFieldName = (fieldName: string) => {
    return fieldName
      .replace(/_/g, ' ')
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const today = new Date().toISOString().split('T')[0];
  const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  const contactPattern = /^\d{10}$/;
  const namePattern = /^[A-Za-z]{3,}$/;
  const pincodePattern = /^\d{6}$/;
  const aadharPattern = /^\d{12}$/;
  const accountHolderNamePattern = /^[A-Za-z\s]{2,50}$/;
  const clubNamePattern = /^[A-Za-z\s_]{2,25}$/;
  const clubDomainNamePattern = /^[a-z]{2,20}$/;

  const calculateAge = (dob: string) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const validateDateRange = (enteringDate: string, leavingDate: string) => {
    const errors: any = {};
    if (enteringDate && leavingDate && new Date(enteringDate) >= new Date(leavingDate)) {
      errors.leaving_date = "Leaving date must be after entering date";
    }

    return errors;
  };

  const validateDateFields = (field: string, dateValue: string) => {
    if (new Date(dateValue) < new Date(today)) {
      errors[field] = `${formatFieldName(field)} cannot be a past date`;
    }
  };

  if (formData && typeof formData === 'object') {
    Object.keys(formData).forEach((field) => {
      const formattedField = formatFieldName(field);

      if (Array.isArray(formData[field]) && formData[field].length > 0 && typeof formData[field][0] === "object") {
        const arrayErrors = formData[field].map((item: any) => validateForm(item));
        const hasArrayErrors = arrayErrors.some((itemErrors: any) => Object.values(itemErrors).some(Boolean));

        if (hasArrayErrors) {
          errors[field] = arrayErrors;
        } else {
          errors[field] = "";
        }
      } else if (formData[field] && typeof formData[field] === "object") {
        errors[field] = validateForm(formData[field]);
      }
      else if (typeof formData[field] === "string") {
        const trimmedValue = formData[field].trim();
        if (trimmedValue === "" && field !== "alternateContactPerson" && field !== "alternateEmail" && field !== "alternatePhoneNo" && field !== "alternateContactNumber") {
          errors[field] = `${formattedField} is required`;
        } else if (field === "email" && !emailPattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "Invalid email format";
        } else if (field === "alternateEmail" && formData[field] && !emailPattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "Invalid email format";
        } else if (field === "phoneNo" && !contactPattern.test(formData[field].toString())) {
          if (!errors[field]) errors[field] = "Invalid contact number format. Please enter exactly 10 digits";
        } else if (field === "alternatePhoneNo" && formData[field] && !contactPattern.test(formData[field].toString())) {
          if (!errors[field]) errors[field] = "Invalid contact number format. Please enter exactly 10 digits";
        }
        else if ((field === "firstName" || field === "lastName") && !namePattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "Only letters allowed, minimum 2 characters required.";
        }
        else if (field === "clubName" && !clubNamePattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "invalid club name, minimum 2 characters required.";
        }
        else if (field === "domainName" && !clubDomainNamePattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "invalid club domain name, minimum 2 characters required.";
        }
        else if (field === "contactPerson" && !accountHolderNamePattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "Only letters allowed, minimum 2 characters required.";
        }
        else if (field === "alternateContactPerson" && formData[field] && !accountHolderNamePattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "Only letters allowed, minimum 2 characters required.";
        }
        else if (field === "aadhar" && !aadharPattern.test(formData[field].toString())) {
          if (!errors[field]) errors[field] = "Invalid Aadhar number format. Please enter exactly 12 digits";
        }
        else if (field === "pincode" && !pincodePattern.test(formData[field])) {
          if (!errors[field]) errors[field] = "Invalid pin code format";
        }
        else if (field === "dateOfBirth") {
          const age = calculateAge(formData[field]);
          if (age < 15) {
            if (!errors[field]) errors[field] = "Minimum Age Requirement is 15 years";
          }
        }
        else if (["late_fee_end_date", "reg_start_date", "reg_end_date", "from_date", "to_date"].includes(field)) {
          validateDateFields(field, formData[field]);
        }
        else if (field === "alternateContactPerson" && formData[field] && formData[field] === formData["contactPerson"]) {
          if (!errors[field]) errors[field] = "Alternate Contact Person cannot be the same as Contact Person";
        } else if (field === "alternateEmail" && formData[field] && formData[field] === formData["email"]) {
          if (!errors[field]) errors[field] = "Alternate Email cannot be the same as Email";
        } else if (field === "alternatePhoneNo" && formData[field] && formData[field] === formData["phoneNo"]) {
          if (!errors[field]) errors[field] = "Alternate Phone Number cannot be the same as Phone Number";
        } else if (field === "email" && !emailPattern.test(formData[field])) {
          errors[field] = "Invalid email format";
        }
        else if (field === "contactNumber" && !contactPattern.test(formData[field].toString())) {
          if (!errors[field]) errors[field] = "Invalid contact number format. Please enter exactly 10 digits";
        } else if (field === "alternateContactNumber" && formData[field] && !contactPattern.test(formData[field].toString())) {
          if (!errors[field]) errors[field] = "Invalid contact number format. Please enter exactly 10 digits";
        }
        else if ((field === "school_phone" || field === "college_phone") && !contactPattern.test(formData[field])) {
          errors[field] = "Invalid contact number format. Please enter exactly 10 digits";
        } else if ((field === "firstName" || field === "lastName") && !namePattern.test(formData[field])) {
          errors[field] = "Minimum 3 characters required";
        } else if (field === "aadhar" && !aadharPattern.test(formData[field].toString())) {
          errors[field] = "Invalid Aadhar number format. Please enter exactly 12 digits";
        } else if (field === "pincode" && !pincodePattern.test(formData[field])) {
          errors[field] = "Invalid pincode format";
        } else if (field === "dateOfBirth") {
          const age = calculateAge(formData[field]);
          if (age < 15) {
            errors[field] = "Minimum Age Requirement is 15 years";
          } else {
            errors[field] = "";
          }
        }
        else if (field === "school_entering_date" || field === "school_leaving_date") {
          const enteringDate = formData.school_entering_date;
          const leavingDate = formData.school_leaving_date;
          const dateErrors = validateDateRange(enteringDate, leavingDate);
          if (dateErrors.leaving_date) {
            errors[field] = dateErrors.leaving_date;
          }
        } else if (field === "college_entering_date" || field === "college_leaving_date") {
          const enteringDate = formData.college_entering_date;
          const leavingDate = formData.college_leaving_date;
          const dateErrors = validateDateRange(enteringDate, leavingDate);
          if (dateErrors.leaving_date) {
            errors[field] = dateErrors.leaving_date;
          }
        } else if (field === "valid_upto") {
          const today = new Date().toISOString().split('T')[0];
          if (new Date(formData[field]) <= new Date(today)) {
            errors[field] = "Valid upto date should be a future date";
          } else {
            errors[field] = "";
          }
        }
        else {
          if (!errors[field]) errors[field] = "";
        }
      }
    });
  }

  return errors;
};

export default validateForm;
