// // addCompetitionFormSlice.ts

// import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// interface AddCompetitionFormState {
//   competitionData: {
//     category_name: string;
//     competitionName: string;
//     competitionCode: string;
//     mqs: string;
//     target: string;
//     creation: string;
//     fromDate: Date | null;
//     toDate: Date | null;
//     registrationStart: Date | null;
//     registrationEnd: Date | null;
//     eligibilityDate: Date | null;
//     lateFeeEndDate: Date | null;
//     place: string;
//     conductedBy: string;
//     // district: string;
//     // preferrd: [{ id: number; name: string }];
//     organisers: [{ secretary_name: string; post: string }];
//     circular: File | null;
//   };
//   selectedEvent: number[];
//   steps: {
//     step1: boolean;
//   };
// }

// const initialState: AddCompetitionFormState = {
//   competitionData: {
//     category_name: "",
//     competitionName: "",
//     competitionCode: "",
//     mqs: "",
//     target: "",
//     creation: "",
//     fromDate: null,
//     toDate: null,
//     registrationStart: null,
//     registrationEnd: null,
//     eligibilityDate: null,
//     lateFeeEndDate: null,
//     place: "",
//     conductedBy: "",
//     // district: "",
//     // preferrd: [{ id: 0, name: "" }],
//     organisers: [{ secretary_name: "", post: "" }],
//     circular: null,
//   },
//   selectedEvent: [],
//   steps: {
//     step1: false,
//   },
// };

// const addCompetitionFormSlice = createSlice({
//   name: "addCompetitionForm",
//   initialState,
//   reducers: {
//     setCompetitionData: (state, action: PayloadAction<AddCompetitionFormState["competitionData"]>) => {
//       state.competitionData = action.payload;
//     },

//     setSelectedEvent: (state, action: PayloadAction<number[]>) => {
//       state.selectedEvent = action.payload;
//     },

//     setCheckError: (state, action) => ({
//       ...state,
//       steps: {
//         step1: action.payload,
//       },
//     }),
//     resetCompetitionForm: () => initialState, // Reset state to initial values
//   },
// });

// export const { setCompetitionData, setSelectedEvent, setCheckError, resetCompetitionForm } = addCompetitionFormSlice.actions;
// export default addCompetitionFormSlice.reducer;





// addCompetitionFormSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AddCompetitionFormState {
  competitionData: {
    comp_code: string;
    name: string;
    place: string;
    conducted_by: string;
    organisers: Array<{ name: string; post: string }>;
    competition_category_id: number | null;
    target_type: number | null;
    detail_creation: string;
    late_fee_end_date: Date | null;
    reg_start_date: Date | null;
    reg_end_date: Date | null;
    cut_off_date: Date | null;
    from_date: Date | null;
    to_date: Date | null;
    is_mqs_applicable: string;
    events: number[];
    circular: string | {},
  };
  selectedEvent: number[];
  steps: {
    step1: boolean;
  };
}

const initialState: AddCompetitionFormState = {
  competitionData: {
    comp_code: "",
    name: "",
    place: "",
    conducted_by: "",
    organisers: [{ name: "", post: "" }],
    competition_category_id: null,
    target_type: null,
    detail_creation: "",
    late_fee_end_date: null,
    reg_start_date: null,
    reg_end_date: null,
    cut_off_date: null,
    from_date: null,
    to_date: null,
    is_mqs_applicable: "",
    events: [],
    circular: "",
  },
  selectedEvent: [],
  steps: {
    step1: false,
  },
};

const addCompetitionFormSlice = createSlice({
  name: "addCompetitionForm",
  initialState,
  reducers: {
    setCompetitionData: (state, action: PayloadAction<AddCompetitionFormState["competitionData"]>) => {
      state.competitionData = action.payload;
    },

    setSelectedEvent: (state, action: PayloadAction<number[]>) => {
      state.selectedEvent = action.payload;
    },

    setCheckError: (state, action: PayloadAction<boolean>) => {
      state.steps.step1 = action.payload;
    },

    resetCompetitionForm: () => initialState, // Reset state to initial values
  },
});

export const { setCompetitionData, setSelectedEvent, setCheckError, resetCompetitionForm } = addCompetitionFormSlice.actions;
export default addCompetitionFormSlice.reducer;
