// import React from 'react';
// import { styled } from '@mui/material/styles';
// import { Select } from '@mui/material';

// const CustomSelect = styled((props: any) => <Select {...props} />)(({ theme }) => ({
//   border: '1px solid #ccc',
//   borderRadius: '8px',
//   '&:focus': {
//     borderColor: '#555',
//   },
//   '& .MuiOutlinedInput-root': {
//     borderRadius: '8px', // Ensure consistent border radius
//     '& fieldset': {
//       borderColor: '#555', // Default border color
//     },
//     '&:hover fieldset': {
//       borderColor: '#555', // Border color on hover
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: '#555', // Border color when focused
//     },
//     '&.Mui-error fieldset': {
//       borderColor: theme.palette.error.main, // Border color when there is an error
//     },
//   },
//   '& .MuiInputLabel-root': {
//     transform: 'translate(14px, 12px) scale(1)', // Initial label position and size
//     backgroundColor: '#fff', // Ensure label background is white
//     padding: '0 8px', // Adjust padding as needed
//     zIndex: 1, // Ensure label is above the border
//     pointerEvents: 'none', // Ensure label does not intercept pointer events
//     transition: theme.transitions.create(['transform', 'background-color'], {
//       duration: theme.transitions.duration.shorter,
//     }),
//   },
//   '& .MuiInputLabel-shrink': {
//     transform: 'translate(14px, -6px) scale(0.75)', // Adjust label position on shrink
//     backgroundColor: '#fff', // Ensure label background is white
//     padding: '0 8px', // Adjust padding as needed
//   },
// }));

// export default CustomSelect;


import React from 'react';
import { styled } from '@mui/material/styles';
import { Select } from '@mui/material';

const CustomSelect = styled((props: any) => <Select {...props} />)(({ }) => ({
  border: '1px solid #ccc',
  borderRadius: '8px',
  '&:focus': {
    borderColor: '#555',
  },
}));

export default CustomSelect;