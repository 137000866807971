import { createSlice } from "@reduxjs/toolkit"

const initialState: any = {
    gaurdianDetails: [{
        relation: "yes",
        name: "",
        occupation: "",
        present_address: "",
        age: "",
        staying_with_applicant: false,
        default_gaurdian: "",
    }],
    student: "",
    studying_college: "",
    educationalDetails: {
        school: [{
            school_name: "",
            school_address: "",
            school_entering_date: "",
            school_leaving_date: "",
            school_passed: "",
            school_phone: "",
            school_marksheet: null,
        }],
        college: [{
            college_name: "",
            college_address: "",
            college_entering_date: "",
            college_leaving_date: "",
            college_passed: "",
            college_phone: "",
            college_marksheet: null,
        }],
        marksheet: null,
    },
    confidentialDetails: {
        income: "",
        business_details: "",
        arm_license: "",
        if_applied: "", // -- if license
        arm_application_status: "pending",
        license_no: "",
        issues_by: "",
        valid_upto: "",
        arm_type: [{
            type: "revolver",
            serial_no: "",
            caliber: "",
        }],
        arm_license_proof: null,
        using_exp_firearms: "",
        details_of_course_handling_guns: "",
        details_of_sports_activity: "",
        other_informations: ""

    }
}

export const RangeUsagesDetails = createSlice({
    name: "RangeUsagesDetails",
    initialState,
    reducers: {
        setGaurdianDetails: (state, action) => {
            state.gaurdianDetails = action.payload;
        },
        setEducationalDetails: (state, action) => {
            state.educationalDetails = action.payload;
        },
        setConfidentialDetails: (state, action) => {
            state.confidentialDetails = action.payload;
        },
        setReset: (state) => {
            return initialState;
        }

    }
})

export const { setGaurdianDetails, setEducationalDetails, setConfidentialDetails, setReset } = RangeUsagesDetails.actions;
export default RangeUsagesDetails.reducer;
