import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography
} from "@mui/material";
import { IconEye } from "@tabler/icons-react";
import { fetchClubAthleteRangeData } from "src/store/clubRegister/RangeRegisterView";
import { AppDispatch, AppState } from "src/store/Store";
import "./style.scss";

const Instruction = () => {
  const { RangeShooter, isLoading } = useSelector((state: AppState) => state.clubAthleteRangeView);
  const dispatch = useDispatch<AppDispatch>();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchClubAthleteRangeData());
  }, [dispatch]);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div className="affilates">
      <div className="comm_form3">
        <div className="range-heading">
          <h3>Instructions for Range Usage Registration</h3>
          <p className="status">
            <b>
              STATUS:{" "}
              <span className="state">
                {RangeShooter ? (
                  RangeShooter.range_usage_status === "pending" ? (
                    "Pending"
                  ) : RangeShooter.range_usage_status === "rejected" ? (
                    "Rejected"
                  ) : RangeShooter.range_usage_status === "approved" ? (
                    "Approved"
                  ) : (
                    "Start Register"
                  )
                ) : (
                  "Start Register"
                )}
              </span>

            </b>
            <br />
            {RangeShooter && RangeShooter.range_usage_status === "rejected" && (
              <b>
                <Button onClick={togglePopup} style={{ textTransform: 'none', color: 'primary' }}>Reason</Button>
              </b>
            )}
          </p>
        </div>

        <div className="range-data">
          <ol>
            <li className="spacer">
              <p>1. Before you proceed to register yourself for Range Usage, you must ensure that you have read and understood the instructions.</p>
            </li>
            <li className="spacer">
              <p>
                2. Applicants should click on <b>'REGISTER'</b> button in order to register.
              </p>
            </li>
            <li className="spacer">
              <p>3. Applicants should fill his / her basic details, education details, parent/guardian details, arm details etc very carefully.</p>
            </li>
            <li className="spacer">
              <p>
                4. Applicants should make sure they enter correct details and upload <b>clear copies</b> of the files.
              </p>
            </li>
            <li className="spacer">
              <p>
                5. Before final submission of the form, applicants will get to see a preview of the form on clicking the NEXT button, read the details carefully and view the files
                that have been uploaded by clicking on the eye icon.
                <span className="align-icon">
                  <IconEye />
                </span>
              </p>
            </li>
            <li className="spacer">
              <p>6. If changes are to be done before submission click on the PREVIOUS button and go to step 1 to make the required changes.</p>
            </li>
            <li className="spacer">
              <p>
                7. Last page of the form will have charges of ranges and weapons. Applicants can choose the appropriate plan (MONTHLY/YEARLY/QUARTERLY) by clicking on checkbox
                besides it and complete the payment. <b>Note: 25% Discount for junior who pay yearly charges.</b>
              </p>
            </li>
            <li className="spacer">
              <p>8. Please note that after successfully submitting the Form, applicants will receive email on their respective Email-id and form will be sent for approval.</p>
            </li>
            <li className="spacer">
              <p>9. Expiry date will be shown against each selected plan after admin approval.</p>
            </li>
            <li className="spacer">
              <p>
                10. After form submission applicants can click on <b>'VIEW'</b> button to see the preview of the form along with the selected plans in order to check expiry date of
                the respective plan.
              </p>
            </li>
            <li className="spacer">
              <p>
                11. After form submission applicants can click on <b>'EDIT'</b> button in order to add new plans or edit the expired plans.{" "}
                <b>Note: The selected plans will not be editable until expiry.</b>
              </p>
            </li>
          </ol>
        </div>
      </div>

      <Dialog open={isPopupVisible} onClose={togglePopup}>
        <DialogTitle>Reason for rejection :</DialogTitle>
        <DialogContent>
          {RangeShooter ? (
            RangeShooter.range_usage_status === "rejected" ? (
              <Typography variant="subtitle2">
                {RangeShooter.rejection_reason}
              </Typography>
            ) : (
              <Typography variant="subtitle2">No rejection reason</Typography>
            )
          ) : (
            <Typography variant="subtitle2">No data available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={togglePopup} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Instruction;
