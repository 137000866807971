import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import axiosServices from "src/utils/axios";
import { notifyMessage } from "src/utils/toastNotify";

interface AthleteState {
  isLoading: boolean;
  adminId: string | null;
  adminResponse: any;
}

const initialState: AthleteState = {
  isLoading: true,
  adminId: null,
  adminResponse: null,
};

export const fetchAdminLoginData = createAsyncThunk("/auth/admin-login/fetchData", async (id: string, { rejectWithValue }) => {
  try {
    const response = await axiosServices.get(`/auth/admin-login/${id}`);

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const adminLoginSlice = createSlice({
  name: "adminLogin",
  initialState,
  reducers: {
    // Additional reducers can be added here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminLoginData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAdminLoginData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.adminResponse = action.payload;
      })
      .addCase(fetchAdminLoginData.rejected, (state) => {
        state.isLoading = false;
        state.adminResponse = null;
      });
  },
});

export default adminLoginSlice.reducer;
