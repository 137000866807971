import React, { useEffect, useState } from "react";
import { Box, Button, Chip, FormControl, FormHelperText, Grid, IconButton, MenuItem, Modal, Stack, Tooltip, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";

import TabPanel from "@mui/lab/TabPanel";
import BlankCard from "src/components/shared/BlankCard";
import TableHead from "src/components/table-head";
import DataTable from "src/components/table/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import APP_ROUTES from "src/routes/routePaths";
import { useNavigate, useParams } from "react-router";
import AlertBox from "src/layouts/full/shared/AlertBox/AlertBox";
import { IconPencil, IconTrash } from "@tabler/icons";
import { IIssfDetails } from "src/types/IssfDetails";

import PageContainer from "src/components/page-container/PageContainer";

import validateForm, { generateErrorInitialState } from "src/utils/FormValidate";
import { notifyMessage } from "src/utils/toastNotify";
import CustomFormLabel from "src/utils/theme-elements/CustomFormLabel";
import { RequiredStar } from "src/components/required-star";
import CustomTextField from "src/utils/theme-elements/CustomTextField";
import CustomSelect from "src/utils/theme-elements/CustomSelect";
import { getAllRanges } from "src/views/clubs/api-call/rangeUse";

import {
  createLockerRoomNo,
  createRange,
  createRangeDuration,
  deleteLockerRoomById,
  deleteRangeById,
  deleteRangeDurById,
  getAdminAllLockerRoomNo,
  getAdminAllLockerType,
  getAdminAllRentedRanges,
  getAdminSingleRentedRanges,
  updateLockerRoomById,
  updateRangeById,
} from "../api-call/adminRangeUse";

import BackLink from "src/components/back-link";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { PreventWheelChange } from "src/utils/PreventWheelChange";

const LockerCreation = () => {
  const [value, setValue] = React.useState("1");

  const [alertOpen, setAlertOpen] = React.useState(false);
  // const [alertDurOpen, setAlertDurOpen] = React.useState(false);

  // const [rangeNames, setRangeName] = useState<string[]>([]);
  // const [inputValues, setInputValues] = useState<number[]>([]);
  const [ranges, setRanges] = useState<any[]>([]);
  const [lockerRoom, setLockerRoom] = useState<any[]>([]);
  const [presentLockerRoom, setPresentLockerRoom] = useState<any[]>([]);
  // const [selectedRangeId, setSelectedRangeId] = useState("");
  const [selectedRangeUpdateId, setSelectedRangeUpdateId] = useState("");
  const [selectedLockerDeleteId, setSelectedLockerDeleteId] = useState("");
  const [selectedLockerUpdateId, setSelectedLockerUpdateId] = useState("");
  const [selectedLocType, setSelectedLocType] = useState("");
  const [selectedLocker, setSelectedLocker] = useState("");
  const [currentLockerNo, setCurrentLockerNo] = useState<number | null>();

  // const [selectedParams, setSelectedParam] = useState("");
  const navigate = useNavigate();

  const { id } = useParams();
  // console.log(id, "checking in page");

  const handleEdit = (id: any) => {
    //setSelectedRangeId(id);
    // console.log(id, "editId");
    setSelectedLockerUpdateId(id);
    setAddOpen(true);
    if (id) {
      const selectedRange = lockerRoom.filter((comp: any) => comp.locker_id === id).map((comp: any) => comp.locker_room_no)[0];
      setFormData((prevState: any) => ({
        ...prevState,
        locker_type_id: Number(selectedLocType),
        locker_room_no: selectedRange,
      }));
      setCurrentLockerNo(selectedRange);
    }

    // navigate(`${APP_ROUTES.ISSF_DETAILS}/edit-view/${id}`);
  };

  const handleOpenAlert = (id: any) => {
    // console.log(id, "rangeid");
    setSelectedLockerDeleteId(id);
    setAlertOpen(true);
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  // const handleCloseDurAlert = () => {
  //   setAlertDurOpen(false);
  // };

  // const handleConfirmAction = () => {
  //   handleCloseAlert();
  // };

  const handleConfirmAction = async () => {
    try {
      // console.log(selectedLockerDeleteId, "deleteid");

      if (selectedLockerDeleteId) {
        const response = await deleteLockerRoomById(selectedLockerDeleteId);
        if (response.success) {
          notifyMessage.success("Locker Room deleted successfully");
          // const response = await fetchRange();
          // setRanges(response);
        } else {
          notifyMessage.error(response.message);
        }
      }
    } catch (error: any) {
      notifyMessage.error("Failed to delete Range");
    } finally {
      const response = await getAdminAllLockerRoomNo(id);
      setLockerRoom(response);
      const lockerRoomNos = response.map((locker: any) => locker.locker_room_no);
      setPresentLockerRoom(lockerRoomNos);
      // console.log(lockerRoomNos);
      handleAddClose();
      handleCloseAlert();
    }
  };

  interface FormData {
    locker_type_id: any;
    locker_room_no: string;
  }

  const [addopen, setAddOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const initialFormdata = {
    locker_type_id: id,
    locker_room_no: "",
  };
  const [formData, setFormData] = React.useState<FormData>(initialFormdata);

  const initialDetailState = { range_name: "" };
  const [detail, setDetail] = useState(initialDetailState);
  // const [detail, setDetail] = useState({
  //   range_name: "",
  // });

  const errorInitialState: any = generateErrorInitialState(formData);
  const [error, setError] = useState(errorInitialState);

  const wholeError = () => {
    const newErrors = validateForm(formData);
    setError(newErrors);
  };

  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
    setFormData(initialFormdata);
    setSelectedRangeUpdateId("");
    setSelectedLockerUpdateId("");
    setError(errorInitialState);
    setCurrentLockerNo(null);
  };

  const fetchRange = async (): Promise<any[]> => {
    try {
      const response = await getAdminAllLockerType();

      return response;
    } catch (error) {
      console.error("Failed to fetch all Locker Type", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchRange();
        // console.log(response, "response");
        setRanges(response);

        if (id) {
          // console.log(id, "id check");
          const selectedLockerTypeName = response.find((comp: any) => comp.locker_type_id == id)?.locker_type_name;
          // console.log(selectedLockerTypeName, "lockerType name");
          setSelectedLocker(selectedLockerTypeName);
          setSelectedLocType(id);
          setFormData((prevState: any) => ({
            ...prevState,
            locker_type_id: Number(id),
          }));
        }

        if (id) {
          const LockerRoomNo = await getAdminAllLockerRoomNo(id);
          // console.log(LockerRoomNo, "lockerRoom no");
          setLockerRoom(LockerRoomNo);

          const lockerRoomNos = LockerRoomNo.map((locker: any) => locker.locker_room_no);
          setPresentLockerRoom(lockerRoomNos);
          // console.log(lockerRoomNos);
        }
      } catch (error) {
        console.error("Failed to fetch ranges", error);
      }
    };

    fetchData();
  }, [id, updateLockerRoomById]);

  const CompetitionColumns: GridColDef[] = [
    { field: "sr_no", headerName: "Sr.No", flex: 0.6 },
    {
      field: "lockerRoomNo", headerName: "Locker Room No", flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value} </div>)
    },
    {
      field: "lockerTypeName", headerName: "Locker Type", flex: 1.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value} </div>)
    },
    {
      field: "isAvailiable",
      headerName: "Available Status",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <Tooltip title="Available Status">
            {params.row.isAvailiable === 0 ? (
              <Typography style={{ color: '#13deb9' }}>Available</Typography>
            ) : (
              <Typography style={{ color: '#fa896a' }}>Not Available</Typography>
            )}
          </Tooltip>
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <IconButton sx={{ cursor: "pointer" }} onClick={() => handleEdit(params.row.id)}>
              <IconPencil size="22" stroke={1.4} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton sx={{ cursor: "pointer" }} onClick={() => handleOpenAlert(params.row.id)}>
              <IconTrash size="22" stroke={1.4} />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const lockerRoomRows = (lockerRoom ?? []).map((ran, index) => ({
    sr_no: index + 1,
    id: ran.locker_id,
    lockerRoomNo: ran.locker_room_no,
    lockerTypeName: ran.locker_type_name,
    isAvailiable: ran.is_alloted,
  }));

  const validateDuplicateFormData = (fieldData: Partial<FormData>): { [key in keyof FormData]?: string } => {
    const errors: { [key in keyof FormData]?: string } = {};

    if (fieldData.locker_room_no !== undefined) {
      // Create a set of locker room numbers to check for duplicates
      const lockerRoomSet = new Set(presentLockerRoom);

      // Remove the current locker room number from the set if in edit mode
      if (currentLockerNo !== null && currentLockerNo !== undefined) {
        lockerRoomSet.delete(currentLockerNo);
      }

      // Check for duplicate locker room number
      if (lockerRoomSet.has(fieldData.locker_room_no)) {
        errors.locker_room_no = 'Locker room number is already taken';
      } else {
        errors.locker_room_no = '';
      }
    }

    return errors;
  };


  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const lockerRoomNo = value.trim() !== "" ? Number(value) : "";

    if (name === "locker_room_no") {
      // Check if we are in edit mode by checking the presence of currentLockerNo
      const isEditMode = currentLockerNo !== null && currentLockerNo !== undefined;

      // Create a set of locker room numbers to check for duplicates
      const lockerRoomSet = new Set(presentLockerRoom);

      // Remove the current locker room number from the set if in edit mode
      if (isEditMode) {
        lockerRoomSet.delete(currentLockerNo);
      }

      // Check for duplicate locker room number
      if (lockerRoomNo !== "" && lockerRoomSet.has(lockerRoomNo)) {
        const newErrors: { [key in keyof FormData]?: string } = { locker_room_no: 'Locker room number is already taken' };
        setError((prevErrors: { [key in keyof FormData]?: string }) => ({ ...prevErrors, ...newErrors }));
      } else {
        setError((prevErrors: { [key in keyof FormData]?: string }) => ({ ...prevErrors, locker_room_no: '' }));
      }

      setFormData({
        ...formData,
        [name as string]: lockerRoomNo,
      });
    }
  };

  // console.log(formData, "formdata in");

  const checkError = (fieldName: keyof FormData) => {
    const newErrors: { [key in keyof FormData]?: string } = validateForm({ [fieldName]: formData[fieldName] });
    setError((prevErrors: { [key in keyof FormData]?: string }) => ({ ...prevErrors, [fieldName]: newErrors[fieldName] }));

    const newDuplicateErrors: { [key in keyof FormData]?: string } = validateDuplicateFormData({ [fieldName]: formData[fieldName] });
    setError((prevErrors: { [key in keyof FormData]?: string }) => ({ ...prevErrors, [fieldName]: newDuplicateErrors[fieldName] }));
  };

  const createFieldHandlers = (fieldName: any) => ({
    onBlur: () => checkError(fieldName),
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const check = Object.values(formData).every((e) => e !== "");
    if (!check) {
      notifyMessage.error("Check all the required fields");
      wholeError();

      return;
    }

    if (formData.locker_room_no == '0' || formData.locker_room_no == ' ') {
      notifyMessage.error("Locker room number cannot be 0.");

      return;
    };

    // Check for errors in the form
    const hasError = Object.values(error).some((err) => err !== "");
    // console.log(error);
    if (hasError) {
      // console.log(hasError);
      // notifyMessage.error("Please fix the errors before submitting");

      return;
    }
    // console.log(formData);
    try {
      // console.log(selectedLockerUpdateId, "selectedLockerUpdateId in try ");

      if (selectedLockerUpdateId) {
        // console.log(selectedLockerUpdateId, "selectedLockerUpdateId If");
        const updateLockerRoom = await updateLockerRoomById(selectedLockerUpdateId, formData);
        // console.log(updateLockerRoom, "updated");
        notifyMessage.success("Locker Room updated Successfully");
      } else {
        // console.log(selectedLockerUpdateId, "selectedLockerUpdateId else");
        const data = {
          "locker_type_id": Number(formData.locker_type_id),
          "locker_room_no": formData.locker_room_no,
        };
        const saveLockerRoomNo = await createLockerRoomNo(data);
        // console.log(saveLockerRoomNo, "saved");
        notifyMessage.success("Locker Room created Successfully");
      }

      const response = await getAdminAllLockerRoomNo(id);
      // console.log("setLockerRoom", response);
      setLockerRoom(response);

      const lockerRoomNos = response.map((locker: any) => locker.locker_room_no);
      setPresentLockerRoom(lockerRoomNos);
      // console.log(lockerRoomNos);
      handleAddClose();
    } catch (error: any) {
      console.error("Error:", error);
      notifyMessage.error(error?.response?.data?.message);
    }
  };

  const handelPropValue = () => {
    // Navigate to '/next' route with state containing 'propValue'
    navigate(`${APP_ROUTES.ADMIN_CLUB_RANGE_USAGES}/locker-room`, { state: { propValue: '1' } });
  };

  return (
    <>
      {/* <BackLink title="Back to Locker Type" route={`${APP_ROUTES.ADMIN_CLUB_RANGE_USAGES}/locker-room`} onClick={handelPropValue} /> */}
      <div
        style={{ display: "flex", alignItems: "center", color: "#000", gap: "10px", marginBottom: "8px", cursor: "pointer" }}
        onClick={handelPropValue}
      >
        <IconArrowNarrowLeft stroke={1.6} /> {"Back to Locker Type"}
      </div>
      <PageContainer>
        <BlankCard>
          <TabContext value={value}>
            <TabPanel value="1">
              <PageContainer title="Range Selection Page" description="this is Range Selection Page">
                <BlankCard>
                  <Box>
                    <Stack sx={{ background: "#ECF2FF", borderRadius: "6px" }} direction="row" justifyContent="space-between" alignItems="center" p={2}>
                      <TableHead title="Locker Number Assign" />

                      <Button onClick={handleAddOpen} variant="contained" color="primary">
                        Add Locker Id
                      </Button>

                      <Modal open={addopen} onClose={handleAddClose}>
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 500,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                          }}
                        >
                          <h2>{currentLockerNo !== null ? "Edit Locker Room" : "Add Locker Room"}</h2>

                          <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} lg={12}>
                              <FormControl fullWidth error={!!error.range_name} margin="normal">
                                <CustomFormLabel sx={{ mt: 0 }} htmlFor={`range_name`}>
                                  LockerType Name <b style={{ color: "red" }}>*</b>
                                </CustomFormLabel>
                                <CustomSelect
                                  labelId={`range_name-label`}
                                  name={`range_name`}
                                  value={selectedLocker}
                                  //onChange={handleChanges}
                                  disabled
                                  {...createFieldHandlers("range_name")}
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {ranges.map((option: any, i) => (
                                    <MenuItem key={i} value={selectedLocker}>
                                      {selectedLocker}
                                    </MenuItem>
                                  ))}
                                </CustomSelect>
                                {error.range_name && <FormHelperText>{error.range_name}</FormHelperText>}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <CustomFormLabel sx={{ mt: 1 }} htmlFor="text-email">
                                Locker Room No.
                                <RequiredStar />
                              </CustomFormLabel>
                              <CustomTextField
                                sx={{ mt: 0 }}
                                id="text-email"
                                variant="outlined"
                                name="locker_room_no"
                                value={formData.locker_room_no}
                                type="number"
                                onWheel={PreventWheelChange}
                                onChange={handleChanges}
                                error={!!error.locker_room_no}
                                helperText={error.locker_room_no}
                                {...createFieldHandlers("locker_room_no")}
                                fullWidth
                                margin="normal"
                              />
                            </Grid>
                          </Grid>

                          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button onClick={handleAddClose} color="error" sx={{ mr: 2 }}>
                              Cancel
                            </Button>
                            <Button onClick={handleSubmit} color="primary" >
                              Submit
                            </Button>
                          </Box>
                        </Box>
                      </Modal>
                    </Stack>
                  </Box>

                  <AlertBox
                    open={alertOpen}
                    disabled={false}
                    title="Confirm Delete"
                    buttonText="Delete"
                    message={<>Are you sure want to delete this data? </>}
                    onClose={handleCloseAlert}
                    onConfirm={handleConfirmAction}
                  />

                  <DataTable rows={lockerRoomRows} columns={CompetitionColumns} tableId="table27" />
                </BlankCard>
              </PageContainer>
            </TabPanel>
          </TabContext>
        </BlankCard>
      </PageContainer>
    </>
  );
};

export default LockerCreation;
