// src/store/slices/ClubAdminProfile.ts
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user_id: null,
  email: '',
  first_name: '',
  last_name: '',
  contact_number: null,
  status: '',
  club_name: '',
  club_id:null,
};

const ClubAdminProfileSlice = createSlice({
  name: 'ClubAdminProfile',
  initialState,
  reducers: {
    setUser(state, action) {
      const { user_id, email, first_name, last_name, contact_number, status, club_name, club_id } = action.payload;
      state.user_id = user_id;
      state.email = email;
      state.first_name = first_name;
      state.last_name = last_name;
      state.contact_number = contact_number;
      state.status = status;
      state.club_name = club_name;
      state.club_id = club_id;
    },
    clearUser(state) {
      state.user_id = null;
      state.email = '';
      state.first_name = '';
      state.last_name = '';
      state.contact_number = null;
      state.status = '';
      state.club_name = '';
      state.club_id = null;
    },
  },
});

export const { setUser, clearUser } = ClubAdminProfileSlice.actions;
export default ClubAdminProfileSlice.reducer;
