import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosServices from "src/utils/axios"; // Ensure this is correctly configured
import { notifyMessage } from "src/utils/toastNotify"; // Make sure this utility is correctly set up

interface AthleteState {
    isLoading: boolean;
    athleteId: string | null;
    RangeShooter: any;
    error: any;
}

const initialState: AthleteState = {
    isLoading: false,
    athleteId: null,
    RangeShooter: null,
    error: null,
};



export const fetchClubAthleteRangeData = createAsyncThunk(
    "club/rangeAthlete/fetchData",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosServices.get(`/range/viewregisteredrangedetails`);
            // console.log(response.data.data, "check call");

            return response.data.data;
        } catch (error: any) {

            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

const rangeShooterSlice = createSlice({
    name: "clubAthleteRangeView",
    initialState,
    reducers: {
        setShooterId(state, action) {
            state.athleteId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchClubAthleteRangeData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchClubAthleteRangeData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.RangeShooter = action.payload;
            })
            .addCase(fetchClubAthleteRangeData.rejected, (state, action) => {
                state.isLoading = false;
                state.RangeShooter = null;
                state.error = action.payload;
            });
    },
});

export const { setShooterId } = rangeShooterSlice.actions;
export default rangeShooterSlice.reducer;
