import React, { useEffect, useState } from "react";
import BackLink from "src/components/back-link";
import APP_ROUTES from "src/routes/routePaths";
import TabPanel from "@mui/lab/TabPanel";
import BlankCard from "src/components/shared/BlankCard";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Tab,
  Typography,
  FormControl,
  IconButton,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomTextField from "src/utils/theme-elements/CustomTextField";
import CustomSelect from "src/utils/theme-elements/CustomSelect";
import CustomFormLabel from "src/utils/theme-elements/CustomFormLabel";
import { CheckRangePurchased, SaveRangeSelection, getAllRanges, getAllRentedRanges } from "../../api-call/rangeUse";
import { AppDispatch, AppState } from "src/store/Store";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getLocalFormData } from "src/services/localStorage/localServices";
import validateForm, { generateErrorInitialState } from "src/utils/FormValidate";
import { nanoid } from "nanoid";
import axios from "axios";
import { IconArrowRight } from "@tabler/icons-react";
import { Navigate, useNavigate } from "react-router";
import { formatDate } from "src/utils/basicFormaters";
import { notifyMessage } from "src/utils/toastNotify";
import { GridColDef } from "@mui/x-data-grid";
import DataTable from "src/components/table/TableComponent";

interface Range {
  range_id: number;
  range_name: string;
  range_period_data: { range_period_duration: string }[];
}

interface RangeSelectionDetail {
  range_name: string;
  duration: string;
  start_date: string;
  end_date: string;
  price: string;
  txn_id: string;
}
interface FormData {
  RangeSelectionDetail: RangeSelectionDetail[];
  total_amount: string;
}

const RangeSelection = () => {
  const [value, setValue] = useState("1");

  const [ranges, setRanges] = useState<Range[]>([]);
  const [purchasedRanges, setPurchasedRanges] = useState<any[]>([]);
  const [rangeNames, setRangeName] = useState<string[]>([]);
  const [rangePeriodDurations, setRangePeriodDurations] = useState<any[]>([]);
  const [rangePeriodType, setRangePeriodType] = useState<string[]>([]);

  const navigate = useNavigate();

  const initialFormData = {
    RangeSelectionDetail: [
      {
        range_name: "",
        duration: "",
        start_date: "",
        end_date: "",
        price: "",
        txn_id: "",
      },
    ],
    total_amount: "",
  };

  const [formData, setFormData] = useState<any>(initialFormData);
  const errorInitialState = generateErrorInitialState(formData);
  const [error, setError] = useState(errorInitialState);
  const [rows, setRows] = useState(initialFormData.RangeSelectionDetail);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [returnedPaymentResponse, setReturnedPaymentResponse] = useState({});
  const [paymentUrl, setPaymentUrl] = useState("");
  const [selectedRangeName, setSelectedRangeName] = useState("");

  const { athleteResponse, isLoading } = useSelector((state: AppState) => state.viewClubAthlete);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleAddRow = () => {
    if (!validateFormData()) {
      return;
    }
    setRows([
      ...rows,
      {
        range_name: "",
        duration: "",
        start_date: "",
        end_date: "",
        price: "",
        txn_id: "",
      },
    ]);
    setFormData((prevData: any) => ({
      ...prevData,
      RangeSelectionDetail: [
        ...prevData.RangeSelectionDetail,
        {
          range_name: "",
          duration: "",
          start_date: "",
          end_date: "",
          price: "",
        },
      ],
    }));
    setError((prevErrors: any) => ({
      ...prevErrors,
      RangeSelectionDetail: [
        ...prevErrors.RangeSelectionDetail,
        {
          range_name: "",
          duration: "",
          start_date: "",
          end_date: "",
          price: "",
        },
      ],
    }));
  };

  const resetFormExceptRangeName = () => {
    setRows([{ range_name: "", duration: "", start_date: "", end_date: "", price: "", txn_id: "" }]);

    setFormData((prevData: any) => ({
      ...prevData,
      RangeSelectionDetail: prevData.RangeSelectionDetail.map((item: any, i: number) =>
        i === 0
          ? {
            ...item,
            duration: "",
            start_date: "",
            end_date: "",
            price: "",
            txn_id: "",
          }
          : item
      ),
      total_amount: "",
    }));

    setError((prevError: any) => ({
      ...prevError,
      RangeSelectionDetail: prevError.RangeSelectionDetail.map((err: any, i: number) =>
        i === 0
          ? {
            ...err,
            duration: "",
            start_date: "",
            end_date: "",
            price: "",
            txn_id: "",
          }
          : err
      ),
      total_amount: "",
    }));
  };

  const resetForm = () => {
    setSelectedRangeName("");
    setRows([{ range_name: "", duration: "", start_date: "", end_date: "", price: "", txn_id: "" }]);

    setFormData((prevData: any) => ({
      ...prevData,
      RangeSelectionDetail: prevData.RangeSelectionDetail.map((item: any, index: number) => ({
        ...item,
        range_name: index === 0 ? "" : "", // Clear range_name for all elements
        duration: index === 0 ? "" : item.duration,
        start_date: index === 0 ? "" : item.start_date,
        end_date: index === 0 ? "" : item.end_date,
        price: index === 0 ? "" : item.price,
        txn_id: index === 0 ? "" : item.txn_id,
      })),
      total_amount: "",
    }));

    setError((prevError: any) => ({
      ...prevError,
      RangeSelectionDetail: prevError.RangeSelectionDetail.map((err: any, index: number) => ({
        ...err,
        range_name: index === 0 ? "" : "", // Clear range_name for all elements
        duration: index === 0 ? "" : err.duration,
        start_date: index === 0 ? "" : err.start_date,
        end_date: index === 0 ? "" : err.end_date,
        price: index === 0 ? "" : err.price,
        txn_id: index === 0 ? "" : err.txn_id,
      })),
      total_amount: "",
    }));
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const generateRandomTxnid = () => {
    const newTxnid = nanoid(40);
    setTransactionId(newTxnid);

    return newTxnid;
  };

  const {
    aadhar_card,
    address,
    city,
    club_name,
    dOB,
    education,
    email,
    event,
    first_name,
    gender,
    last_name,
    membership_type,
    phone,
    pincode,
    safety_course,
    state_name,
    state_unit,
  } = athleteResponse;

  const handleOpenDialog = async () => {
    if (!validateFormData()) {
      return;
    }
    const response = await CheckRangePurchased(formData);
    // console.log(response, "RangeSelectionData");

    if (response && (response.status === 200)) {
      if (response.data.success === false) {
        notifyMessage.error(response.data.message);
        console.error(response.data.message);

        return;
      }
    }

    if (formData.total_amount == 0) {
      await handleNext();

      return;
    }

    setDialogOpen(true);

    try {
      // console.log(club_name, "heyyy");
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/pay?stateunit=${club_name}`, {
        // txnid: transactionId,
        txnid: generateRandomTxnid(),
        amount: formData.total_amount,
        email: email,
        phone: phone,
        firstname: first_name + " " + last_name,
        productinfo: "Range Selection",
        surl: `${process.env.REACT_APP_BASE_URL}/api/payment-response?stateunit=${club_name}`,
        furl: `${process.env.REACT_APP_BASE_URL}/api/payment-response?stateunit=${club_name}`,
      });

      // // console.log(response, "for pay");
      const paymentUrl = response.data;

      setPaymentUrl(paymentUrl);
      setReturnedPaymentResponse(response.data);
    } catch (error) {
      console.error("Error posting data to payment API:", error);
    }
  };

  const validateFormData = () => {
    let isValid = true;
    const newErrors = formData.RangeSelectionDetail.map((item: any, index: any) => {
      const itemErrors: any = {};
      if (!item.range_name) {
        isValid = false;
        itemErrors.range_name = "Range name is required";
      }
      if (!item.duration) {
        isValid = false;
        itemErrors.duration = "Duration is required";
      }

      return itemErrors;
    });

    setError({ RangeSelectionDetail: newErrors });
    if (!isValid) {
      notifyMessage.error("Please fill in all required fields.");
    }

    return isValid;
  };

  const checkPaymentStatus = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/check-payment-status/${transactionId}?stateunit=${club_name}`);
      // // console.log(response.data, "resulttttttttttttt");

      return response.data.status;
    } catch (error) {
      console.error("Error checking payment status:", error);

      return false;
    }
  };

  const handleNext = async () => {
    // console.log(returnedPaymentResponse, "hee");
    try {
      const updatedFormData = {
        ...formData,
        RangeSelectionDetail: formData.RangeSelectionDetail.map((detail: any) => ({
          ...detail,
          txn_id: transactionId,
        })),
      };

      const response = await SaveRangeSelection(updatedFormData);
      // console.log(response, "RangeSelectionData");

      // if (response && (response.status === 200 || response.status === 201)) {
      if (response && response.success === true) {
        notifyMessage.success("Range Purchased Successfully!");
        const purchasedRanges = await fetchRentedRange();
        setPurchasedRanges(purchasedRanges);
        setValue("2");
        resetForm();
        //setRows([{ range_name: "", duration: "", start_date: "", end_date: "", price: "", txn_id: "" }]);
        // // console.log("Ranges saved successfully");
        // navigate("/club-range-usages");
      } else {
        console.error("Failed to save ranges in club:", response);
      }
    } catch (error: any) {
      console.error("An error occurred while saving the range selection:", error);
    }
  };

  const handlePaymentAndContinue = async () => {
    const paymentWindow = window.open(paymentUrl, "_blank");

    const pollPaymentStatus = async () => {
      try {
        const paymentStatus = await checkPaymentStatus();
        if (paymentStatus !== "failure") {
          handleNext();
        } else {
          notifyMessage.error("Payment was not successful. Please try again.");
          // resetForm();
        }
      } catch (error) {
        // console.log("Error checking payment status:", error);
      }
    };

    const intervalId = setInterval(async () => {
      const paymentStatus = await checkPaymentStatus();
      if (paymentStatus) {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        pollPaymentStatus();
      }
    }, 2000);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      if (paymentWindow) {
        paymentWindow.close();
      }
      resetForm();
      notifyMessage.error("Payment check timed out. Please try again.");
    }, 120000);

    handleCloseDialog();
  };

  const handleRemoveRow = (indexToRemove: number) => {
    setRows((prevRows: any) => prevRows.filter((_: any, index: any) => index !== indexToRemove));
    setFormData((prevData: any) => ({
      ...prevData,
      RangeSelectionDetail: prevData.RangeSelectionDetail.filter((_: any, index: any) => index !== indexToRemove),
    }));
    setError((prevErrors: any) => ({
      ...prevErrors,
      RangeSelectionDetail: prevErrors.RangeSelectionDetail.filter((_: any, index: any) => index !== indexToRemove),
    }));
  };

  const singleError = (fieldName: any, index: any) => {
    const newErrors: any = validateForm({ [fieldName]: formData.RangeSelectionDetail[index][fieldName] });
    setError((prevErrors: any) => ({
      ...prevErrors,
      RangeSelectionDetail: prevErrors.RangeSelectionDetail.map((err: any, i: any) => (i === index ? { ...err, [fieldName]: newErrors[fieldName] } : err)),
    }));
  };

  const wholeError = () => {
    const newErrors = validateForm(formData);
    setError(newErrors);
  };

  const createFieldHandlers = (index: any, fieldName: string) => ({
    onChange: handleChange(index, fieldName),
    onBlur: () => singleError(fieldName, index),
  });

  // console.log(formData, "check data");

  const handleChange = (index: any, field: any) => (e: any) => {
    e.preventDefault();
    const { value, type, checked } = e.target;

    if (field === "range_name") {
      // Clear any period durations related to this field
      setRangePeriodDurations([]);

      // Clear errors related to this field
      setError((prevError: any) => ({
        ...prevError,
        RangeSelectionDetail: prevError.RangeSelectionDetail.map((err: any, i: any) =>
          i === 0 ? { ...err, range_name: "" } : err
        ),
      }));

      // Update form data, resetting related fields in the first item
      setFormData((prevData: any) => ({
        ...prevData,
        RangeSelectionDetail: prevData.RangeSelectionDetail.map((item: any, i: any) =>
          i === 0
            ? {
              ...item,
              range_name: value,
              duration: "",
              start_date: "",
              end_date: "",
              price: "",
              txn_id: "",
            }
            : item
        ),
      }));

      resetFormExceptRangeName();

      return;
    }


    const today = new Date();

    setRows((prevRows: any) => prevRows.map((row: any, i: any) => (i === index ? { ...row, [field]: type === "checkbox" ? checked : value } : row)));

    let newFormData: any = {
      ...formData,
      RangeSelectionDetail: formData.RangeSelectionDetail.map((item: any, i: any) => (i === index ? { ...item, [field]: type === "checkbox" ? checked : value } : item)),
    };

    if (field === "duration") {
      newFormData = {
        ...formData,
        RangeSelectionDetail: formData.RangeSelectionDetail.map((item: any, i: any) => {
          if (i !== index) return item;

          const endDate = new Date();
          const [selectedDurations, selectedRangePeriodType] = value.split("-");
          const selectedDuration = parseInt(selectedDurations);

          if (selectedRangePeriodType === "day") {
            endDate.setDate(today.getDate() + selectedDuration);
          } else if (selectedRangePeriodType === "month") {
            endDate.setDate(today.getDate() + selectedDuration * 30);
          }

          const priceFromData = rangePeriodDurations.find((duration) => `${duration.duration}-${duration.type}` === value)?.price || 0;

          return {
            ...item,
            [field]: type === "checkbox" ? checked : value,
            start_date: today.toISOString().split("T")[0],
            end_date: endDate.toISOString().split("T")[0],
            price: priceFromData,
          };
        }),
      };
    } else {
      newFormData = {
        ...formData,
        RangeSelectionDetail: formData.RangeSelectionDetail.map((item: any, i: any) => (i === index ? { ...item, [field]: type === "checkbox" ? checked : value } : item)),
      };
    }

    const totalAmount = newFormData.RangeSelectionDetail.reduce((total: any, item: any) => total + parseFloat(item.price || 0), 0).toFixed(2);

    // Add total amount to form data
    newFormData = {
      ...newFormData,
      total_amount: totalAmount,
    };

    setFormData(newFormData);
  };

  // console.log(ranges, rangeNames, rangePeriodDurations, "for view data");
  // console.log(purchasedRanges, "purcahsed data");

  const fetchRange = async (): Promise<Range[]> => {
    try {
      const response = await getAllRanges();
      const competitionsData: Range[] = response;

      return competitionsData;
    } catch (error) {
      console.error("Failed to fetch competitions", error);
      throw error;
    }
  };

  const fetchRentedRange = async (): Promise<any[]> => {
    try {
      const response = await getAllRentedRanges();
      const rentedRangeData: any[] = response;
      setPurchasedRanges(rentedRangeData);

      return rentedRangeData;
    } catch (error) {
      console.error("Failed to fetch competitions", error);
      throw error;
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetchRange();
      setRanges(response);

      const rangeName = response.map((comp) => comp.range_name);
      setRangeName(rangeName);

      if (selectedRangeName) {
        const rangePeriodDurations = response
          .filter((comp) => comp.range_name === selectedRangeName)
          .map((comp: any) =>
            comp.range_period_data.map((period: any) => ({
              id: period.range_period_id,
              duration: period.range_period_duration,
              type: period.range_period_type,
              price: period.range_amount,
            })),
          )
          .flat();
        setRangePeriodDurations(rangePeriodDurations);
      }

      const rangePeriodType = response.map((comp: any) => comp.range_period_data.map((period: any) => period.range_period_type)).flat();
      setRangePeriodType(rangePeriodType);

      const purchasedRanges = await fetchRentedRange();
      setPurchasedRanges(purchasedRanges);
    } catch (error) {
      console.error("Failed to fetch ranges", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedRangeName]);

  useEffect(() => {
    fetchRentedRange();
  }, []);

  const historyColumns: GridColDef[] = [
    {
      field: 'srNo',
      headerName: 'Sr no.',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value || 'N/A'} </div>)
    },
    {
      field: 'rangeName',
      headerName: 'Range Name',
      flex: 1.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value || 'N/A'} </div>)
    },
    {
      field: 'duration',
      headerName: 'Duration',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value || 'N/A'} </div>)
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value || 'N/A'} </div>)
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value || 'N/A'} </div>)
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params.value + ' ₹' || 'N/A'} </div>)
    },
  ];

  // Convert data
  const historyRows = purchasedRanges.map((item, index) => ({
    id: index,
    srNo: index + 1,
    rangeName: item.range_name,
    duration: `${item.range_period_duration} ${item.range_period_type}`,
    startDate: formatDate(item.start_date),
    endDate: formatDate(item.end_date),
    price: item.payment_amount ? item.payment_amount : '0',

  }));

  return (
    <Box sx={{ width: "100%" }}>
      <BackLink title="Get Back To Range Utilities" route={APP_ROUTES.ATHLETE_CLUB_RANGE_USAGES} />
      <BlankCard>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example" variant="scrollable" scrollButtons="auto">
              <Tab label="Range selection" value="1" />
              <Tab label="Purchased History" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
              <Typography variant="h5" gutterBottom>
                Purchase Range
              </Typography>
            </Box>
            <Box mt={2}>
              {rows.map((row: any, index: any) => (
                <Box border={1} borderColor="grey.300" borderRadius={2} mt={2} key={index}>
                  <Grid container spacing={2} mb={2}>
                    {/* First Row */}
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={6} sx={{ marginLeft: 2.5 }}>
                          <FormControl fullWidth error={!!error.RangeSelectionDetail[index]?.range_name} margin="normal">
                            <CustomFormLabel sx={{ mt: 0 }} htmlFor={`range_name-${index}`}>
                              Range Name <b style={{ color: 'red' }}>*</b>
                            </CustomFormLabel>
                            <CustomSelect
                              labelId={`range_name-label-${index}`}
                              name={`range_name-${index}`}
                              value={selectedRangeName} // Update the value attribute here
                              onChange={(e: any) => {
                                setSelectedRangeName(e.target.value);
                                handleChange(index, 'range_name')(e); // Update form data when range name changes
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {rangeNames.map((option, i) => (
                                <MenuItem key={i} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                            {error.RangeSelectionDetail[index]?.range_name && (
                              <FormHelperText>{error.RangeSelectionDetail[index]?.range_name}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={5.5} /* sx={{  }} */>
                          <FormControl fullWidth error={!!error.RangeSelectionDetail[index]?.duration} margin="normal">
                            <CustomFormLabel sx={{ mt: 0 }} htmlFor={`duration-${index}`}>
                              Duration <b style={{ color: 'red' }}>*</b>
                            </CustomFormLabel>
                            <CustomSelect
                              labelId={`duration-label-${index}`}
                              name={`duration-${index}`}
                              value={row.duration}
                              {...createFieldHandlers(index, 'duration')}
                            >
                              {rangePeriodDurations.length === 0 ? (
                                <MenuItem disabled>
                                  No duration available
                                </MenuItem>
                              ) : (
                                rangePeriodDurations.map((option, i) => (
                                  <MenuItem key={i} value={`${option.duration}-${option.type}`}>
                                    {`${option.duration} ${option.type}`}
                                  </MenuItem>
                                ))
                              )}

                            </CustomSelect>
                            {error.RangeSelectionDetail[index]?.duration && (
                              <FormHelperText>{error.RangeSelectionDetail[index]?.duration}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Second Row */}
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={3} sx={{ marginLeft: 2.5 }}>
                          <FormControl fullWidth>
                            <CustomFormLabel sx={{ mt: 0 }} htmlFor={`start_date-${index}`}>
                              Start Date <b style={{ color: 'red' }}>*</b>
                            </CustomFormLabel>
                            <CustomTextField
                              name={`start_date-${index}`}
                              type="date"
                              value={formData.RangeSelectionDetail[index].start_date}
                              fullWidth
                              disabled
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "rgba(0, 0, 0, 1)",
                                },
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{}}>
                          <FormControl fullWidth>
                            <CustomFormLabel sx={{ mt: 0 }} htmlFor={`end_date-${index}`}>
                              End Date <b style={{ color: 'red' }}>*</b>
                            </CustomFormLabel>
                            <CustomTextField
                              name={`end_date-${index}`}
                              type="date"
                              value={formData.RangeSelectionDetail[index].end_date}
                              fullWidth
                              disabled
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "rgba(0, 0, 0, 1)",
                                },
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={5.5} sx={{}}>
                          <CustomFormLabel sx={{ mt: 0 }} htmlFor={`price-${index}`}>
                            Price <b style={{ color: 'red' }}>*</b>
                          </CustomFormLabel>
                          <CustomTextField
                            fullWidth
                            name={`price-${index}`}
                            placeholder="00.0"
                            value={formData.RangeSelectionDetail[index].price}
                            disabled
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "rgba(0, 0, 0, 1)",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
            <>
              {rows.length >= 1 && (
                <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 4 }}>
                  <Button size="large" onClick={handleOpenDialog} variant="contained">
                    <Typography variant="subtitle2" mr={1}>
                      {formData.total_amount == 0 ? 'Submit' : 'Pay & Continue'}
                    </Typography>
                    <IconArrowRight size={22} fontWeight={"800"} stroke="#ccc" />
                  </Button>
                </Box>
              )}
              <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md">
                <DialogTitle>Redirecting to Payment Gateway</DialogTitle>
                <DialogContent sx={{ padding: "32px", fontSize: "1.2rem" }}>
                  <Table sx={{ minWidth: 750 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold", padding: "16px" }}>Sr no.</TableCell>
                        <TableCell sx={{ fontWeight: "bold", padding: "16px" }}>Range Name</TableCell>
                        <TableCell sx={{ fontWeight: "bold", padding: "16px" }}>Duration</TableCell>
                        <TableCell sx={{ fontWeight: "bold", padding: "16px" }}>Start Date</TableCell>
                        <TableCell sx={{ fontWeight: "bold", padding: "16px" }}>End Date</TableCell>
                        <TableCell sx={{ fontWeight: "bold", padding: "16px" }}>Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formData.RangeSelectionDetail.map((row: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell sx={{ padding: "16px" }}>{index + 1}</TableCell>
                          <TableCell sx={{ padding: "16px" }}>{row.range_name}</TableCell>
                          <TableCell sx={{ padding: "16px" }}>{row.duration}</TableCell>
                          <TableCell sx={{ padding: "16px" }}>{row.start_date}</TableCell>
                          <TableCell sx={{ padding: "16px" }}>{row.end_date}</TableCell>
                          <TableCell sx={{ padding: "16px" }}>{row.price}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter style={{ borderTop: "2px solid #ddd" }}>
                      <TableRow>
                        <TableCell colSpan={5} align="right" sx={{ padding: "16px" }}>
                          <Typography variant="subtitle2" fontWeight={600}>
                            Total:
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ padding: "16px" }}>
                          <Typography variant="subtitle2" fontWeight={600}>
                            ₹{formData.total_amount}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </DialogContent>

                <DialogActions style={{ marginRight: '15px' }}>
                  <Button onClick={handleCloseDialog} color="error">
                    Cancel
                  </Button>
                  <Button onClick={handlePaymentAndContinue} color="primary">
                    Proceed
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          </TabPanel>
          <TabPanel value="2">
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h5" gutterBottom>
                Purchase History
              </Typography>
            </Box>
            <DataTable rows={historyRows} columns={historyColumns} checkbox={false} tableId="table49" />
          </TabPanel>
        </TabContext>
      </BlankCard>
    </Box>
  );
};

export default RangeSelection;