import React, { useEffect, useState } from "react";
import BackLink from "src/components/back-link";
import APP_ROUTES from "src/routes/routePaths";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, AppState, dispatch } from "src/store/Store";
import { fetchClubAthleteData, setShooterId } from "src/store/clubRegister/ClubAthleteViewEdit";
import "./style.scss";
import { useParams } from "react-router";
import { jwtDecode } from "jwt-decode";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getClubDetailById, getClubId } from "../../api-call/rangeUse";
import { fetchTenantByIdStart } from "src/store/reducers/TenentSlice";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import FileUpload from "src/components/upload-file/UploadFile";

const PreviewForm = () => {
  const { athleteId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [selectedDocument, setSelectedDocument] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [clubDetail, setClubDetail] = useState<any>(null);
  const [clubId, setClubId] = useState<any>();
  const { athleteResponse, isLoading } = useSelector((state: AppState) => state.viewClubAthlete);
  const { tenant, loading, error } = useSelector((state: AppState) => state.tenent);
  // console.log(tenant, "tenantdata");
  // console.log(athleteId, athleteResponse, isLoading);
  const RangeStore = useSelector((state: AppState) => state.rangeUsages);
  // console.log(RangeStore, "reducer inside preview");

  // Extracting data from Redux store
  const { educationalDetails, gaurdianDetails, confidentialDetails } = RangeStore;
  const { school, college } = educationalDetails;

  // console.log("School", school);

  const {
    income,
    business_details,
    arm_license,
    if_applied,
    arm_application_status,
    license_no,
    issues_by,
    valid_upto,
    arm_license_proof,
    using_exp_firearms,
    details_of_course_handling_guns,
    details_of_sports_activity,
    other_informations,
  } = RangeStore.confidentialDetails;

  const handleViewDocument = (documentType: string) => {
    setSelectedDocument(documentType);
    // console.log(documentType, "file type");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDocument("");
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    // console.log(token);
    if (token) {
      const decodedToken: any = jwtDecode(token);
      // console.log(decodedToken, "decoded");
      if (decodedToken) {
        const { userId } = decodedToken;
        dispatch(setShooterId(userId));
        dispatch(fetchClubAthleteData({ id: userId }));
      }
    }
  }, [dispatch]);

  const {
    aadhar_card,
    address,
    city,
    club_name,
    dOB,
    education,
    email,
    event,
    first_name,
    gender,
    last_name,
    membership_type,
    phone,
    pincode,
    safety_course,
    state_name,
    state_unit,
  } = athleteResponse;

  // console.log(clubDetail, "hiir");

  const date = new Date().toISOString().split("T")[0];

  // console.log(educationalDetails.marksheet, confidentialDetails.arm_license_proof, "file");

  // const marksheetURL = URL.createObjectURL(educationalDetails.marksheet);
  // const armLicenseURL = URL.createObjectURL(confidentialDetails.arm_license_proof);
  //const marksheetURL = educationalDetails?.marksheet ? URL.createObjectURL(educationalDetails.marksheet) : URL.createObjectURL(new Blob());
  //const armLicenseURL = confidentialDetails?.arm_license_proof ? URL.createObjectURL(confidentialDetails.arm_license_proof) : URL.createObjectURL(new Blob());

  // const marksheetURL = educationalDetails?.marksheet ?? URL.createObjectURL(new Blob());
  // const armLicenseURL = confidentialDetails?.arm_license_proof ?? URL.createObjectURL(new Blob());

  // console.log(athleteResponse, "from mappings");

  useEffect(() => {
    dispatch(fetchTenantByIdStart(clubId));
  }, [dispatch, clubId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ClubIds = await getClubId(club_name);
        // console.log(ClubIds.id, "idd");
        setClubId(ClubIds.id);
      } catch (error) {
        console.error("Failed to fetch ClubDetails", error);
      }
    };

    fetchData();
  }, [club_name, dispatch]);

  return (
    <>
      <div className="comm_form3">
        <div className="range-heading">
          <h3>
            {tenant.name}
            <br />
            {tenant.name} Ranges, {tenant.address} {tenant.addressTwo} {tenant.addressThree} {tenant.city} - {tenant.pincode}
            <br />
            Tel. {tenant.contactNumber} {/* / {tenant.alternateContactNumber} */}
            <br />
            <span className="caps">Private &amp; Confidential</span>
          </h3>
        </div>
        <div className="range-data">
          <ol>
            <li className="spacer">
              <p>
                1. Name of Applicant :{" "}
                <b>
                  {" "}
                  {first_name} {last_name}
                </b>
              </p>
              <p>
                Address:{" "}
                <b>
                  {address} ,{state_name} ,{city}- {pincode}
                </b>
              </p>
              <p>
                Tel / E-mail Id:{" "}
                <b>
                  {phone} / {email}{" "}
                </b>
              </p>
            </li>
            <li className="spacer">
              <p>
                2. Qualification: SSC/HSC/Post Graduate/Other qualifications
                <br />
                Education qualification showing places of education with year in school
              </p>
              <table className="table table-bordered darkborder" id="school_preview_tbl">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Date of Entering</th>
                    <th>Date of Leaving</th>
                    <th>Examinations passed</th>
                    <th>Telephone Number</th>
                  </tr>
                </thead>
                <tbody>
                  {school.map((schoolItem: any, index: any) => (
                    <tr key={index}>
                      <td>{schoolItem.school_name}</td>
                      <td>{schoolItem.school_address}</td>
                      <td>{schoolItem.school_entering_date}</td>
                      <td>{schoolItem.school_leaving_date}</td>
                      <td>{schoolItem.school_passed}</td>
                      <td>{schoolItem.school_phone}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="college_div">
                Education qualification showing places of education with year in college
                <table className="table table-bordered darkborder" id="college_preview_tbl">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Date of Entering</th>
                      <th>Date of Leaving</th>
                      <th>Examinations passed</th>
                      <th>Telephone Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {college.map((collegeItem: any, index: any) => (
                      <tr key={index}>
                        <td>{collegeItem.college_name}</td>
                        <td>{collegeItem.college_address}</td>
                        <td>{collegeItem.college_entering_date}</td>
                        <td>{collegeItem.college_leaving_date}</td>
                        <td>{collegeItem.college_passed}</td>
                        <td>{collegeItem.college_phone}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p>
                Self attested copy of marksheet/certificate:
                <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => handleViewDocument("marksheet")}>
                  <VisibilityIcon />
                </IconButton>
                <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md">
                  {selectedDocument === "arm_license_proof" && (
                    <DialogContent>
                      {confidentialDetails.arm_license_proof ? (
                        <>
                          {confidentialDetails.arm_license_proof instanceof File ? (
                            <img
                              src={URL.createObjectURL(confidentialDetails.arm_license_proof)}
                              alt="arm_license_proof"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : typeof confidentialDetails.arm_license_proof === "string" ? (
                            <img
                              src={confidentialDetails.arm_license_proof}
                              alt="arm_license_proof"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div>Image not available...</div>
                          )}
                        </>
                      ) : (
                        <div>Image not available...</div>
                      )}
                    </DialogContent>
                  )}

                  {selectedDocument === "marksheet" && (
                    <DialogContent>
                      {educationalDetails.marksheet ? (
                        <>
                          {educationalDetails.marksheet instanceof File ? (
                            <img
                              src={URL.createObjectURL(educationalDetails.marksheet)}
                              alt="marksheet"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : typeof educationalDetails.marksheet === "string" ? (
                            <img
                              src={educationalDetails.marksheet}
                              alt="marksheet"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div>Image not available...</div>
                          )}
                        </>
                      ) : (
                        <div>Image not available...</div>
                      )}
                    </DialogContent>
                  )}

                  <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                  </DialogActions>
                </Dialog>
              </p>
            </li>
            <li className="spacer">
              <p>
                3. Brief Details of Business / Profession / Job :{" "}
                <b>
                  <span className="business_details">{business_details}</span>
                </b>
              </p>
              <p>
                Monthly / Yearly Income:{" "}
                <b>
                  <span className="income">{income}</span>
                </b>
              </p>
            </li>
            <li className="spacer">
              <p>4. Detail of family member staying with the applicant</p>
              <p></p>
              <table className="table table-bordered darkborder" id="relation_tbl">
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>AGE</th>
                    <th>OCCUPATION</th>
                    <th>RELATION</th>
                    <th>ADDRESS</th>
                  </tr>
                </thead>
                <tbody>
                  {gaurdianDetails.map((guardian: any, index: any) => (
                    <tr key={index}>
                      <td>{guardian.name}</td>
                      <td>{guardian.age}</td>
                      <td>{guardian.occupation}</td>
                      <td>{guardian.relation}</td>
                      <td>{guardian.present_address}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p></p>
            </li>
            <li className="spacer">
              <p>
                5. Whether Arms License is applied?{" "}
                <b>
                  <span className="license_applied">{if_applied}</span>
                </b>{" "}
                {/* <b>
                  <span className="arm_result">{arm_license}</span>
                </b> */}
              </p>
              <p>
                Whether Application is Pending or License was Granted / Rejected :{" "}
                <b>
                  <span className="arm_application_status">{arm_application_status}</span>
                </b>
              </p>
              <p className="col-md-3">Details of Arms: </p>
              <p className="col-md-3">
                No:{" "}
                <b>
                  <span className="license_no">{license_no}</span>
                </b>
              </p>
              <p className="col-md-3">
                Issued By:{" "}
                <b>
                  <span className="issued_by">{issues_by}</span>
                </b>
              </p>
              <p className="col-md-3">
                Valid Up to:{" "}
                <b>
                  <span className="valid_upto">{valid_upto}</span>
                </b>
              </p>
            </li>
            <li className="spacer">
              <p>6. Details Of Arms</p>
              <p></p>
              <table className="table table-bordered darkborder" id="arm_tbl">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Sr.no</th>
                    <th>Caliber</th>
                  </tr>
                </thead>
                <tbody>
                  {confidentialDetails.arm_type && confidentialDetails.arm_type.map((arm: any, index: any) => (
                    <tr key={index}>
                      <td>{arm.type}</td>
                      <td>{arm.serial_no}</td>
                      <td>{arm.caliber}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p></p>
            </li>
            <li className="spacer">
              <p>
                7. Details of arms license and weapon possessed by each above mentioned members:
                <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => handleViewDocument("arm_license_proof")}>
                  <VisibilityIcon />
                </IconButton>
              </p>
            </li>
            <li className="spacer">
              <p>
                8. Experience in Using Firearms:{" "}
                <b>
                  <span className="experience_in_firearms">{using_exp_firearms}</span>
                </b>
              </p>
              <p>
                Details of Course Attended in Handling of Guns:{" "}
                <b>
                  <span className="details_of_course">{details_of_course_handling_guns}</span>
                </b>
              </p>
            </li>
            <li className="spacer">
              <p>
                9. Details of other sports activities if any:{" "}
                <b>
                  <span className="details_of_sports">{details_of_sports_activity}</span>
                </b>
              </p>
            </li>
            <li className="spacer">
              <p>
                10. Other Information:{" "}
                <b>
                  <span className="other_information">{other_informations}</span>
                </b>{" "}
              </p>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default PreviewForm;
