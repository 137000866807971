// fileUploadConfig.ts
export const allowedFormats = {
  OnlyImage: ['image/jpeg', 'image/png', 'image/svg+xml'],
  ImageAndPDF: ['application/pdf', 'image/jpeg', 'image/png', 'image/svg+xml'],
  // Add other configurations as needed
};

export const maxFileSize = {
  TwoMB: 2048 * 1024, // 2 MB
  FiveMB: 5 * 1024 * 1024, // 5 MB
  // Add other configurations as needed
};
